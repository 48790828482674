import React, { useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import LabelName from 'components/UI/LabelName/LabelName';

import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';

import constants from 'constants/index';
import { downloadAttachments } from 'services/calendarServices';
import { generateFullName } from 'utils/generateFullNameUtils';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { GroupUserTooltip } from 'components/UI/GroupUserTooltip/GroupUserTooltip';
import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import ImageComponent from 'shared/ImageComponent';
import { getProfileImageFilePath, handleRedirection, parseReminderData } from 'utils/utils';
import { Menu } from 'primereact/menu';
import { clearLocalStorageItems, handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import { usePracticeAreaList } from 'hooks/usePracticeAreaList';
import { reminderChannels, reminderOptions } from 'constants/dropdownOptions';
import AttendeesPreview from './AttendeesPreview';
import { InputSwitch } from 'primereact/inputswitch';

function EventAndTaskPreview({
  eventDetails,
  setCancelEvent,
  handleEdit,
  cancelEventPermission,
  editEventPermission,
  isClosed,
  isFromCaseSpecificModule,
  isFromLeadSpecificModule,
}) {
  const userContext = useContext(UserDetailsContext);
  const { practiceAreaList } = usePracticeAreaList();

  const { isClientCalendar } = useCalendarContext();

  const history = useHistory();

  const opanel = useRef();

  const items = [
    {
      label: 'Edit Event',

      command: () => {
        handleEdit();
      },
      disabled: !editEventPermission,
    },
    {
      label: 'Delete Event',
      command: () => {
        setCancelEvent(true);
      },
      disabled: !cancelEventPermission,
    },
  ];

  const onClickAttachments = (e, fileInfo) => {
    e.preventDefault();
    downloadAttachments(fileInfo)
      .then((data) => {
        let link = document.createElement('a');
        link.download = fileInfo.display_name;
        let url = window.URL.createObjectURL(data.Body);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigateToModule = (item, module) => {
    if (!isClientCalendar) {
      clearLocalStorageItems(['pk', 'sk']);

      let url = '/';
      if (module === 'cases') {
        if (item.is_lead) {
          url = item.case_id ? `/leads/${item.case_id}` : '/leads';
        } else {
          url = item.case_id ? `/cases/${item.case_id}` : '/cases';
        }
      } else if (module === 'groups') {
        if (item.group_status === 'Deleted') {
          return;
        }
        url = '/settings/groups';
      } else if (module === 'users') {
        if (item?.access_level && item?.access_level !== 'client') {
          url = item?.firm_user_id ? `/settings/team-management/${item?.firm_user_id}` : '/settings/team-management';
        } else {
          url = item?.contact_id ? `/contacts/${item?.contact_id}` : '/contacts';
        }
      }

      handleRedirection(history, url);
    }
  };
  const getStatusLabel = (option) => {
    if (option) {
      if (option?.is_confirmed === false && (option?.status === 'ACTIVE' || option?.status === undefined)) {
        return ' (Unconfirmed)';
      } else if ((option?.is_confirmed || option?.is_confirmed === undefined) && option?.status === 'ARCHIVED') {
        return ' (Archived)';
      } else if (option?.is_confirmed === false && option?.status === 'ARCHIVED') {
        return ' (Archived)';
      }
    }
    return '';
  };

  function AttendeeList({ users, groups, nameKey }) {
    if ((!users || users.length === 0) && (!groups || groups.length === 0)) {
      return <div>No attendees</div>;
    }
    return (
      <>
        {groups?.map((group, index) => (
          <div className="d-flex mb-1" key={index}>
            <ImageComponent filePath={group?.profile_image} fileName={group?.group_name} fileSize="small.jpg" />{' '}
            <div
              onClick={() => navigateToModule(group, 'groups')}
              className={classNames({
                pointer: true,
                // 'link-primary': (!isClientCalendar && group?.group_status !== 'Deleted') || group?.group_status !== 'Deleted',
                'text-primary-dark': (!isClientCalendar && group?.group_status !== 'Deleted') || group?.group_status !== 'Deleted',
                'disabled-element': group?.group_status === 'Deleted',
                ['name-' + index]: true,
                'mx-2 align-items-center d-flex': true,
              })}
            >
              <Tooltip target={`.name-${index}`} position="top">
                {GroupUserTooltip(group, users)}
              </Tooltip>
              {group?.group_name} {group?.group_status === 'ARCHIVED' && '(Archived)'}
            </div>
          </div>
        ))}
        {users.map((item, index) =>
          item?.is_individual_user === true ? (
            <div className="d-flex mb-1" key={index}>
              {/* <i className="icon-calendar me-2 mt-1"></i> */}
              <ImageComponent
                filePath={item?.profile_image || getProfileImageFilePath(item?.firm_user_id || item?.contact_id)}
                fileName={item.name}
                fileSize="small.jpg"
                style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
              />
              <div
                onClick={() => navigateToModule(item, 'users')}
                className={classNames({
                  // 'link-primary pointer': !isClientCalendar,
                  'text-primary-dark pointer': !isClientCalendar,
                  'mx-2 align-items-center d-flex': true,
                })}
              >
                {item?.firm_user_id && <i className="fas fa-user-tie me-2"></i>}
                {item[nameKey]}
                {getStatusLabel(item)}
              </div>
            </div>
          ) : (
            (groups?.length === 0 || !groups) && <div>No attendees</div>
          )
        )}
      </>
    );
  }

  return (
    <>
      {eventDetails ? (
        <form>
          {/* form heading */}
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div className="modal-heading F-size16 text-bold">{eventDetails?.res_type === 'event' ? 'Event Details' : 'Task Details'}</div>
            {eventDetails?.pk &&
            eventDetails?.res_type === 'event' &&
            eventDetails?.upcoming_event?.type !== 'SOL' &&
            !isClosed &&
            !isClientCalendar ? (
              <>
                <div className="pointer">
                  <div
                    className="d-flex border border-1 rounded  justify-content-center p-2 task-lane-header-options pointer"
                    onClick={(e) => opanel?.current?.toggle(e)}
                  >
                    <i className="pi pi-ellipsis-v" />
                  </div>
                </div>
                <Menu model={items} popup ref={opanel} id="popup_menu" />
              </>
            ) : null}
          </div>
          {/* title */}
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12 ">
              <LabelName required={false} htmlFor="title">
                {eventDetails?.res_type === 'event' ? 'Event Title' : 'Task Title'}
              </LabelName>
            </div>
            {/* <div className="col-md-8 col-12 input-style"> */}
            <div className="col-md-8 col-12">{eventDetails?.res_type === 'event' ? eventDetails?.title : eventDetails?.task_name}</div>
          </div>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12">
              <LabelName required={false} htmlFor="title">
                Case
              </LabelName>
            </div>
            <div className="col-md-8 col-12">
              <div className="mb-2 casename-container text-center F-size13 m-0">
                {eventDetails?.case_name ? (
                  <p className="pointer mb-0 p-2 react-tagsinput-tag" onClick={() => navigateToModule(eventDetails, 'cases')}>
                    {eventDetails?.case_name}
                  </p>
                ) : (
                  'No case selected'
                )}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12">
              <LabelName required={false} htmlFor="title">
                Date
              </LabelName>
            </div>
            <div className="col-md-8 col-12">
              {handleDateTimeOffset(
                userContext.userDetails.timezone,
                eventDetails?.res_type === 'event' ? eventDetails?.meeting_start_time : eventDetails?.due_date,
                constants.month_date_year_format
              )}
            </div>
          </div>

          {eventDetails?.res_type === 'event' && (
            <>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12">
                  <LabelName required={false} htmlFor="title">
                    Time
                  </LabelName>
                </div>
                <div className="col-md-4 col-12 d-flex gap-3 align-items-center">
                  <div className="w-100">
                    {handleDateTimeOffset(
                      userContext.userDetails.timezone,
                      eventDetails?.meeting_start_time,
                      constants.hour_minute_12_format
                    )}
                  </div>
                  <div className="text-bold px-4">-</div>
                  <div className="w-100">
                    {handleDateTimeOffset(
                      userContext.userDetails.timezone,
                      eventDetails?.meeting_end_time,
                      constants.hour_minute_12_format
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12">
                  <LabelName required={false} htmlFor="title">
                    Location
                  </LabelName>
                </div>
                {eventDetails?.location ? (
                  <div className="col-md-8 col-12">{eventDetails?.location}</div>
                ) : (
                  <div className="col-md-8 col-12"> None</div>
                )}
              </div>
              {eventDetails?.case_name === '' && (
                <div className="d-flex align-items-center py-2 flex-wrap">
                  <div className="col-md-4 col-12">
                    <LabelName required={false} htmlFor="title">
                      Practice Area{' '}
                    </LabelName>
                  </div>
                  {eventDetails?.practice_area && eventDetails?.practice_area?.length > 0 ? (
                    <div className="col-md-8 col-12">
                      {practiceAreaList
                        ?.filter((option) => eventDetails?.practice_area?.includes(option.sk))
                        .map((area, index) => (
                          <span key={index}>{area.practice_area_name}</span>
                        ))}
                    </div>
                  ) : (
                    <div className="col-md-8 col-12"> None</div>
                  )}
                </div>
              )}
            </>
          )}

          <div className="d-flex py-2 flex-wrap">
            <div className="col-md-4 col-12">
              <LabelName required={false} htmlFor="title">
                Created by
              </LabelName>
            </div>
            <div
              className="col-md-8 col-12 redirection-link-no-text-transform"
              onClick={() => {
                const { sk, pk, created_by_firm_user_id } = eventDetails;
                const url = created_by_firm_user_id ? `/settings/team-management/${created_by_firm_user_id}` : '/settings/team-management';

                handleRedirection(history, url, eventDetails);
                handleSettingSkAndPkToLocalStorage(sk, pk);
              }}
            >
              {eventDetails?.created_by_firm_user_id && <i className="fas fa-user-tie me-2"></i>}
              {generateFullName(eventDetails?.created_by_details)}
            </div>
          </div>
          <div className="d-flex py-2 flex-wrap">
            <div className="col-md-4 col-12">
              <LabelName required={false} htmlFor="title">
                {eventDetails?.res_type === 'event' ? 'Attendees' : 'Assignees'}
              </LabelName>
            </div>
            <div className="col-md-8 col-12">
              <>
                {eventDetails?.res_type === 'event' ? (
                  <AttendeeList users={eventDetails?.guests} groups={eventDetails?.groups} nameKey="name" />
                ) : (
                  <AttendeeList users={eventDetails?.assign_to} groups={eventDetails?.groups} nameKey="assignee_name" />
                )}
              </>
            </div>
          </div>
          <div className="d-flex py-2 flex-wrap">
            <AttendeesPreview
              selectedGroups={eventDetails.groups}
              selectedFirmUsers={eventDetails.firm_users}
              selectedContacts={eventDetails.contacts}
              history={history}
            />
          </div>
          {eventDetails?.res_type === 'event' && (
            <div className="d-flex py-2 flex-wrap">
              <div className="col-md-4 col-12">
                <LabelName required={false} htmlFor="title">
                  Attachments
                </LabelName>
              </div>
              {eventDetails?.attachments?.length > 0 ? (
                <div className="col-12 col-lg-8">
                  <ul className="px-0">
                    {eventDetails?.attachments?.map((file, index) => {
                      return (
                        <li className="li-style-none d-flex text-break" key={index}>
                          <i className="fas fa-paperclip me-2 mt-1"></i>
                          <a
                            href="/"
                            onClick={(e) => {
                              onClickAttachments(e, file);
                            }}
                          >
                            {file?.file_name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <div className="col-md-8 col-12">No attachments added</div>
              )}
            </div>
          )}
          {parseReminderData(eventDetails.reminder)?.length > 0 && (
            <div className="d-flex py-2 flex-wrap">
              <div className="col-md-4 col-12">
                <LabelName required={false} htmlFor="reminder">
                  Reminder
                </LabelName>
              </div>
              <div className="col-md-8 col-12">
                {parseReminderData(eventDetails.reminder)?.map((data, index) => {
                  return (
                    <div
                      className="d-flex input-shadow align-items-center justify-content-center p-2 mb-2 bg-grey"
                      style={{ borderRadius: 12 }}
                      key={data?.reminder_channel_id}
                    >
                      <div className="w-100">
                        <div>{reminderChannels?.find((channel) => channel?.value === data?.reminder_channel_type)?.label}</div>
                        <div>
                          {data?.value === 'custom' ? (
                            <>
                              <div>{data?.value}</div>
                              <div>{`${data?.customValue} ${data?.type}`}</div>
                            </>
                          ) : (
                            reminderOptions.find((channel) => channel.value === data?.value)?.label
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-4 col-12">
              <LabelName required={false} htmlFor="is_recurring">
                Recurring Event
              </LabelName>
            </div>
            <div className="col-md-8 col-12 d-flex align-items-center justify-content-start text-capitalize edit-field">
              <div className="w-100 ">
                <InputSwitch
                  name={'is_recurring'}
                  value={eventDetails?.is_recurring}
                  checked={eventDetails?.is_recurring}
                  className="input-swich"
                  placeholder="Recurring Event"
                  disabled={true}
                />
              </div>
            </div>
          </div>
          {eventDetails?.is_recurring && (
            <>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12">
                  <LabelName required={false} htmlFor="recurrence_limit">
                    Till Date
                  </LabelName>
                </div>
                <div className="col-md-8 col-12">
                  {handleDateTimeOffset(
                    userContext.userDetails.timezone,
                    eventDetails?.recurrence_keys.recurrence_limit,
                    constants.month_date_year_format
                  )}
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-md-4 col-12">
                  <LabelName required={false} htmlFor="recurrence_frequency">
                    Frequency
                  </LabelName>
                </div>
                <div className="col-md-8 col-12">{eventDetails?.recurrence_keys.recurrence_frequency}</div>
              </div>
              {eventDetails?.recurrence_keys.recurrence_frequency === 'nth_weekday' && (
                <>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12">
                      <LabelName required={false} htmlFor="week_occurrence">
                        Week Occurrence
                      </LabelName>
                    </div>
                    <div className="col-md-8 col-12">{eventDetails?.recurrence_keys.week_occurrence}</div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12">
                      <LabelName required={false} htmlFor="weekday">
                        Weekday
                      </LabelName>
                    </div>
                    <div className="col-md-8 col-12">{eventDetails?.recurrence_keys.weekday}</div>
                  </div>
                </>
              )}
            </>
          )}
        </form>
      ) : (
        <div className="d-flex justify-content-center">Loading....</div>
      )}
    </>
  );
}

export default EventAndTaskPreview;

import { useEffect, useState } from 'react';
import { totalCountEvent } from 'services/generalServices';
import { listUsers } from 'services/users/userServices';
import { sortByKey } from 'utils/utils';

export function useFirmUserList() {
  const [List, setList] = useState([]);

  const loadUserList = () => {
    totalCountEvent('user')
      .then((response) => {
        listUsers({ status: 'ACTIVE', limit: response.data, is_confirmed_user: true })
          .then((response) => {
            const sortedList = sortByKey(response.data?.firm_users, 'first_name');
            setList(sortedList);
          })
          .catch((err) => console.error(err));
      })
      .catch((error) => console.warn(error));
  };

  useEffect(() => {
    loadUserList();
  }, []);

  return { userList: List };
}
